import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
const Telesan = () => {
    return (
        
    <section id = "telesan-hero1" >
        <div className = "telesan-row">
            <div className = "texto">
                <h1>¿Qué es Telesan?</h1>
                <p>
                Es una iniciativa de innovación que conecta unidades remotas de salud con 
                hospitales de alta especialidad a través de las tecnologías de la 
                información y comunicación (TICs)
                </p>
                <div className = "circles">
                    <FontAwesomeIcon icon={faCircle} />
                    <FontAwesomeIcon icon={faCircle} />
                    <FontAwesomeIcon icon={faCircle} />
                </div>
            </div>
            <div className = "columns grafico">
                <StaticImage
                    src = "../../assets/sobrenosotros/sobretelesan.JPG"
                    width = {700}
                    alt= "Telesan teleconsulta"
                />
            </div>
        </div>  
    </section>
    )
}

export default Telesan