import React from "react"
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Premios from "../icons/Premios"
import { StaticImage } from "gatsby-plugin-image"

const Timeline = () => {
    return (
    <div id = "premios" >
        <h1>Premios y Reconocimientos</h1>
        <VerticalTimeline>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: '#F2F2F2', color: '#2f3e8d' }}
                contentArrowStyle={{ borderRight: '7px solid  #2f3e8d' }}
                date="2019"
                iconStyle={{ background: '#9cc444', color: '#fff' }}
                icon={<Premios />}
            
            >
                <h3 className="vertical-timeline-element-title">
                    EuroSAN Innova
                </h3>
                <p>
                En el marco del Proyecto EUROSAN Occidente, la Unión Europea y 
                el Gobierno de Honduras promueven EUROSAN INNOVA, un concurso de 
                subvenciones para el desarrollo y testeo en campo de ideas, 
                prácticas y tecnologías innovadoras que contribuyan a 
                solucionar/mitigar problemas relacionados con la SAN de Honduras.
                </p>
                <StaticImage
                        src = "../../assets/sobrenosotros/eurosan_innova2019.jpg"
                        width = {400}
                        alt= "Telesan teleconsulta"
                    />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: '#F2F2F2', color: '#2f3e8d' }}
                contentArrowStyle={{ borderRight: '7px solid  #2f3e8d' }}
                date="2019"
                iconStyle={{ background: '#9cc444', color: '#fff' }}
                icon={<Premios />}
            
            >
                <h3 className="vertical-timeline-element-title">
                    Social Skin
                </h3>
                <p>
                Premio multilatino de innovación social, está dirigido a jóvenes
                 emprendedores que contribuyen a la solución de problemáticas sociales. 
                 Nuestras categorías están asociadas a los Objetivos de Desarrollo Sostenible 
                 (ODS) definidos por la ONU.

                </p>
                <StaticImage
                        src = "../../assets/sobrenosotros/social_skin_2019.png"
                        width = {400}
                        alt= "Telesan teleconsulta"
                    />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                contentStyle={{ background: '#F2F2F2', color: '#2f3e8d' }}
                contentArrowStyle={{ borderRight: '7px solid  #2f3e8d' }}
                         className="vertical-timeline-element--work"
                date="2020"
                iconStyle={{ background: '#9cc444', color: '#fff' }}
                icon={<Premios />}
            >
                <h3 className="vertical-timeline-element-title">
                    Cemex
                </h3>
                <p>
                Reconocimiento anual, de alcance global, al desarrollo de propuestas 
                y proyectos de alto impacto en materia de desarrollo sostenible, 
                emprendimiento e innovación social.
                </p>
                <StaticImage
                        src = "../../assets/sobrenosotros/premios_cemex_2020.jpg"
                        width = {400}
                        alt= "Telesan teleconsulta"
                    />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: '#F2F2F2', color: '#2f3e8d' }}
                contentArrowStyle={{ borderRight: '7px solid  #2f3e8d' }}
                date="2020"
                iconStyle={{ background: '#9cc444', color: '#fff' }}
                icon={<Premios />}
            >
                <h3 className="vertical-timeline-element-title">
                    Bayer Ideas que Vuelan
                </h3>
                <p>
                Se trata de la cuarta edición de la iniciativa “Ve+Allá” ideas que valen, 
                un concurso de compromiso social que realiza la empresa Bayer para dar la 
                oportunidad a mujeres líderes comunales para que desarrollen su idea y 
                logren atender necesidades sociales en los países donde operan sus negocios. 
                Premiando iniciativas para mejorar la calidad de vida de sus comunidades
                 en cuanto a nutrición y salud en países de Centroamérica y en República Dominicana, 
                 Colombia, Perú, Venezuela y Ecuador.

                </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: '#F2F2F2', color: '#2f3e8d' }}
                contentArrowStyle={{ borderRight: '7px solid  #2f3e8d' }}
                date="2021"
                iconStyle={{ background: '#9cc444', color: '#fff' }}
                icon={<Premios />}
            >
                <h3 className="vertical-timeline-element-title">
                    El Premio a la Innovación Juvenil Rural en América Latina y el Caribe
                </h3>
                <p>
                Es una de las primeras iniciativas del Centro de Conocimiento y Cooperación Sur-Sur 
                del Fondo Internacional de Desarrollo Agrícola (FIDA) de las Naciones
                 Unidas y cooperación triangular entre China y el FIDA.  El Premio busca 
                 identificar, premiar y difundir iniciativas innovadoras y sostenibles 
                 realizadas por jóvenes de países de América Latina y el Caribe.

                 <StaticImage
                    src = "../../assets/telesan_telesalud.jpg"
                    width = {400}
                    alt= "Telesan "
                 />

                </p>
            </VerticalTimelineElement>

        </VerticalTimeline>
    </div>
    )
}

export default Timeline;
