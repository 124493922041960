import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import Hero from "../components/acercade/Hero"
import Telesan from "../components/acercade/Telesan"
import Objetivos from "../components/acercade/Objetivos"
import Timeline from "../components/acercade/Timeline"


const sobrenosotros = (props) => (
  <Layout>
    <Seo title="Acerca de" />
    <Hero/>
    <Telesan/>
    <Objetivos/>
    <Timeline/>
  </Layout>
);

export default sobrenosotros;

