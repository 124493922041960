import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
const Objetivos = () => {
    return (
    <section id = "telesan-hero2">
        <div className = "telesan-row">
                <div className = "columns grafico">
                    <StaticImage
                        src = "../../assets/sobrenosotros/telesan_objetivos.jpg"
                        width = {700}
                        alt= "Telesan teleconsulta"
                    />]
                </div>
                <div className = "texto">
                    <h1>Objetivos</h1>
                    <p>
                        <ul>
                            <li>Aumentar el acceso y seguimiento de los servicios	de salud de 
                                la población que asiste a los establecimientos de	Salud de la red 
                                pública a través de teleconsulta, tele	diagnóstico y teleeducación.</li>
                            <li>
                                Fortalecer	las capacidades para la atención a través de servicios de 
                                <em>telesalud</em> en las redes públicas de salud.
                            </li>

                        </ul>
                        <div className = "circles">
                            <FontAwesomeIcon icon={faCircle} />
                            <FontAwesomeIcon icon={faCircle} />
                            <FontAwesomeIcon icon={faCircle} />
                        </div>
                    </p>
                </div>
        </div>
    </section>
    )
}

export default Objetivos